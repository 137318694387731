
.container {
  height: 40px;
  display: flex;
  align-items: center;
}
.loading-line-container {
  width: 100%;
  height: 4px;
  background-color: #f3f3f3;
  overflow: hidden;
  position: relative;
}

.loading-line {
  width: 100%;
  height: 100%;
  background-color: #2EC9B7;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}