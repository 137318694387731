.rbc-allday-cell{
  display: none;
}

.rbc-time-content{
  border-top: none !important;
}

.rbc-button-link span{
  color: #6C6C6C;
}

.rbc-time-slot span{
  color: #6C6C6C !important;
}

.rbc-header{
  text-align: center !important;
  border-bottom: none !important;
}

.white-content .rbc-time-content > * + * > *{
border-color: #d9d9d9 !important;
}

.rbc-day-slot .rbc-time-slot + .rbc-time-slot {
  border-top: 1px dotted #d9d9d9 !important;
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

.white-content .rbc-today {
  background: #fff !important;
}

.rbc-row.rbc-time-header-cell {
  border-bottom: 1px solid #d9d9d9 !important;
}

.white-content .rbc-timeslot-group{
  border-color: #d9d9d9 !important;
}

.white-content .rbc-time-header-content{
  border: none;
}

.rbc-event {
  background-color: #BFF2EC95 !important;

}

.rbc-day-slot .rbc-event{
  border: none !important;

}

.rbc-day-slot .rbc-event-label {
  padding: 10px;
  color: #3D3D3D !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}
